






































































































































































import Vue from "vue";
import { ResponsePagination } from "@/models/interface/common.interface";
import moment from "moment";
import {
  changeCurrencytoNumeric,
  currencyFormat,
} from "@/validator/globalvalidator";
import { Messages } from "@/models/enums/messages.enum";
import { settingsServices } from "@/services/settings.service";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataRelatedCode, ResponseListOfTaxInvoiceCode } from "@/models/interface/settings.interface";
import { mapGetters } from "vuex";
export default Vue.extend({
  name: "TaxInvoiceCode",
  data() {
    return {
      formatCurrencytoNumber: changeCurrencytoNumeric,
      titlemodalOpen:"" as string,
      idParams:"" as string,
      modalOpen : false as boolean,
      disabledTaxInvoiceCode: false as boolean,
      switchRevisionCode: false as boolean,
      totalData: 0 as number,
      loaddingDataListTaxInvoicecode: false as boolean,
      dataListTaxInvoicecode: [] as ResponseListOfTaxInvoiceCode[],
      listDeletedTax: [] as any[],
      listRelatedCode: [] as DataRelatedCode[],
      LoadingDatalistRelatedCode: false as boolean,
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      limit: 10 as number,
      page: 0 as number,
      loadingTable: false as boolean,
      isFormSubmitted: false as boolean,
      form: this.$form.createForm(this, { name: "taxInvoiceCode" }),
      formRules: {
        taxInvoiceCode: {
          label: "Tax Invoice Code",
          name: "code",
          placeholder: "Insert Tax Invoice Code",
          decorator: [
            "code",
            {
              rules: [
                {
                  required: true,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        description: {
          label: "Description",
          name: "description",
          placeholder: "Insert Description",
          decorator: [
            "description",
            {
              rules: [
                {
                  required: true,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        relatedCode: {
          label: "Related Code",
          name: "relatedCode",
          placeholder: "Insert Related Code",
          decorator: [
            "relatedCode",
            {
              rules: [
                {
                  required: false,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        revisionCode: {
          label: "Revision Code",
          name: "revisionCode",
          placeholder: "Insert Revision Code",
          decorator: [
            "revisionCode",
            {
              rules: [
                {
                  required: true,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
      },
      columnsTable: [
        {
          title: "Tax Invoice Code",
          dataIndex: "code",
          key: "code",
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Description",
          dataIndex: "description",
          key: "description",
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Related Code",
          dataIndex: "relatedCode",
          key: "relatedCode",
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
      ] as any,
    };
  },
  computed: {
    ...mapGetters({
      getUserPrivileges: "authStore/GET_USER_PRIVILEGES",
    }),
    hasPrivilegeCreate(): boolean {
      return this.getUserPrivileges.find(x => x.key === "tax-invoice-code" && x.privilege.update && x.privilege.create);
    },
  },
  created(){
    this.getRelatedCode();
    this.columnsTable.push({
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      scopedSlots: { customRender: "operation" },
      button: ["update"],
      width: 120,
      align: "center",
      fixed: "right",
    });
  },
  mounted(){
    this.getListTaxCode();
  },
  methods: {
    moment,
    changeRevision(e){
      if (!e) this.form.setFieldsValue({
        relatedCode: null
      });
    },
     reponseEditTable(response){
      this.modalOpen = true;
      this.titlemodalOpen = "Edit Tax Invoice Code";
      this.idParams = response.data.id;
      this.disabledTaxInvoiceCode = true;
      setTimeout(() => {
                this.form.setFieldsValue({
          code : response.data.code,
          description : response.data.description,
          relatedCode : response.data.relatedCode,
      });
      this.switchRevisionCode =response.data.revisionCode;
      }, 500);
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    getRelatedCode(){
        let params = {
        search: "revisionCode~false_AND_used~false",
        limit: 10 , 
        page:0,
      } as RequestQueryParamsModel;
      this.LoadingDatalistRelatedCode = true;
      settingsServices.listOfTaxInvoiceCode(params).then((res: any) => {
          res.data.map((item,index) => (item.key = index , item.name = item.code , item.id = item.code));
          this.listRelatedCode = res.data;
      }).finally(()=> this.LoadingDatalistRelatedCode = false);
    },
    handleCancel():void{
      this.modalOpen = false;
    },
    addRow():void{
      this.modalOpen = true;
      this.titlemodalOpen = "Create Tax Invoice Code";
      this.form.resetFields();
      this.idParams = "";
      this.disabledTaxInvoiceCode = false;
      this.switchRevisionCode= false;
    },

    getListTaxCode(){
      let params ={
        limit : this.limit,
        page: this.page,
      } as RequestQueryParamsModel;
      this.loaddingDataListTaxInvoicecode = true;
      settingsServices.listOfTaxInvoiceCode(params).then((res: any) => {
          res.data.map((item,index) => (item.key = index));
          this.dataListTaxInvoicecode = res;
      }).finally(()=> this.loaddingDataListTaxInvoicecode = false);
    },
    save(e): void {
      e.preventDefault();
      this.form.validateFields((err , value) => {
        if (!err){
            value["revisionCode"] = this.switchRevisionCode;
            value["relatedCode"] = value.relatedCode ? value.relatedCode : "";
            if (!this.idParams){
            settingsServices.createTaxInvoiceCode(value).then(
              () => {
                this.getListTaxCode();
                this.getRelatedCode();
                this.modalOpen = false;
                this.$message.success(Messages.CREATE_SUCCESS);
              }
            );
            }else{
              settingsServices.updateTaxInvoiceCode(value , this.idParams).then(() => {
                this.$message.success(Messages.UPDATE_SUCCESS);
                this.getListTaxCode();
                this.getRelatedCode();
                this.modalOpen = false;
              }
            );
            }
            
        }
      });
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.getListTaxCode();
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page -1;
      this.getListTaxCode();
    },
  },
});
